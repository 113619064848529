import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { SESSION_ID_KEY } from 'app/constants/session';
import SessionTimeout from 'components/SessionTimeout';
import { errorMessages } from 'app/constants/errorMessages';
import { apiCall } from 'utils/apiUtils';
import { useStore } from 'store';

const useJwtRedirect = () => {
  const history = useHistory();
  const [courseId, setCourseId] = useState();
  const [fakeJwt, setFakeJwt] = useState();

  // if course id changes, set a new course id
  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (query.get('courseId') && query.get('courseId') !== courseId) {
      setCourseId(query.get('courseId'));
    }
  });

  // when there's a new courseId, grab a fake Jwt
  useEffect(() => {
    const fetchFakeJwt = async () => {
      const request = {
        method: 'get',
        url: '/test/jwt',
        params: {
          courseId
        }
      };
      try {
        const response = await apiCall({ request });
        setFakeJwt(response.token);
        history.push(`/?${response.token}`);
      } catch (e) {
        throw new Error('Course Id not found');
      }
    };

    if (courseId) fetchFakeJwt();
  }, [courseId]);

  return [courseId, fakeJwt];
};

const SessionToken = () => {
  const history = useHistory();
  const { search } = useLocation();

  // Have to apply the substring on search to remove the prefixed ?
  const sessionId = search ? search.substring(1) : sessionStorage.getItem(SESSION_ID_KEY);

  const {
    userSession: { accessToken },
    setUserSession
  } = useStore((state) => state.authStore);
  const handleError = useStore((state) => state.actions.handleError);

  useJwtRedirect();

  const query = new URLSearchParams(history.location.search);

  useEffect(() => {
    if (!sessionStorage.length) {
      // Ask other tabs for session storage
      localStorage.setItem('getSessionStorage', Date.now());
    }

    const checkStorageForSessionId = (event) => {
      if (event.key === 'getSessionStorage') {
        // Some tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));

        // Then remove from local storage
        localStorage.removeItem('sessionStorage');
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        const data = (event.newValue && JSON.parse(event.newValue)) || {};
        const newSessionId = data[SESSION_ID_KEY] || sessionStorage.getItem(SESSION_ID_KEY);

        if (newSessionId && !query.get('courseId')) {
          setUserSession(newSessionId);
        } else {
          handleError(errorMessages.noSessionId);
        }
      }
    };

    window.addEventListener('storage', checkStorageForSessionId);

    if (sessionId && !query.get('courseId')) {
      setUserSession(sessionId);
    } else {
      setTimeout(() => checkStorageForSessionId({ key: 'sessionStorage' }), 500);
    }

    return () => {
      window.removeEventListener('storage', checkStorageForSessionId);
    };
  }, [sessionId]);

  if (!accessToken) {
    return null;
  }

  return <SessionTimeout />;
};

export default SessionToken;
