import { useEffect, useRef } from 'react';
import useCountDown from 'react-countdown-hook';

import { useStore } from 'store';

const INTERVAL = 60000; // Check the time left every minute
const TIME_TO_SHOW_WARNING = 300000;

const SessionTimeout = () => {
  const {
    userSession: { timeUntilSessionExpires },
    sessionHasExpired,
    showSessionExpiryWarning
  } = useStore((state) => state.authStore);

  const [timeLeft, { start, reset }] = useCountDown(
    timeUntilSessionExpires > 0 ? timeUntilSessionExpires : 0,
    INTERVAL
  );

  const timerStarted = useRef(null);
  const warningShownRef = useRef(false);

  useEffect(() => {
    if (timeUntilSessionExpires <= 0) {
      // Session has already expired, so we can inform the user

      sessionHasExpired();
    } else {
      // Start the session timer
      start();
    }
  }, [start, reset, timeUntilSessionExpires]);

  useEffect(() => {
    if (!timerStarted.current && timeLeft > 0) {
      // Workaround for the react-countdown-hook, which defaults the timer to 0 on initialisation
      timerStarted.current = true;
    }

    const showWarning = timeLeft > 0 && timeLeft <= TIME_TO_SHOW_WARNING;
    const sessionExpired = timerStarted.current && timeLeft <= 0;

    if (sessionExpired) {
      sessionHasExpired();
    }

    if (showWarning && !warningShownRef.current) {
      showSessionExpiryWarning();
      // Prevent the warning from being shown again
      warningShownRef.current = true;
    }
  }, [timeLeft]);

  return null;
};

export default SessionTimeout;
