/* eslint-disable react/prop-types */
import React, { memo, useCallback, useEffect, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';
import { connectRefinementList } from 'react-instantsearch-dom';

import { callStatusLabels } from 'app/constants/callStatuses';
import { StyledAutocomplete } from './MultiSelectFilter.styles';
import { StyledCheckbox, StyledSelect, StyledSelectValue } from 'styles';
import { useStore } from 'store';

const capitaliseFirstChar = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const CALL_STATUS_ATTRIBUTE = 'interventionStatusId';

const MultiSelectFilter = memo(({ items, label, filterKey }) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const updateFilter = useStore((store) => store.searchStore.updateFilter);
  const noActiveFilters = useStore((store) => isEmpty(store.searchStore.activeFilters));
  const filtersForKey = useStore((store) =>
    store.searchStore.activeFilters.reduce((filters, filter) => {
      if (filter[filterKey]) {
        filters.push(filter[filterKey]);
      }

      return filters;
    }, [])
  );

  const onChangeFilter = useCallback((value) => updateFilter(filterKey, value), [filterKey]);

  useEffect(() => {
    if (noActiveFilters) {
      setOpen(false);
    }
  }, [noActiveFilters]);

  if (isEmpty(items)) {
    return null;
  }

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  if (isTablet) {
    return (
      <>
        <InputLabel htmlFor={`${filterKey}-input`}>{label}</InputLabel>
        <StyledSelect
          multiple
          labelId={`${filterKey}-input`}
          displayEmpty
          active={filtersForKey && filtersForKey.length > 0}
          value={filtersForKey}
          onChange={(evt) => onChangeFilter(evt.target.value)}
          input={
            <OutlinedInput
              endAdornment={
                filtersForKey &&
                filtersForKey.length > 0 && (
                  <InputAdornment position="end">
                    <CloseIcon onClick={() => onChangeFilter([])} data-ref="clear-search-button" />
                  </InputAdornment>
                )
              }
            />
          }
          fullWidth
          renderValue={(selected) => (
            <StyledSelectValue>
              {selected.length === 0 ? 'Show all' : <strong>{selected.length} selected</strong>}
            </StyledSelectValue>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                width: 250
              }
            }
          }}
        >
          {items.map((value) => (
            <MenuItem key={value} value={value}>
              <StyledCheckbox checked={filtersForKey.indexOf(value) > -1} small />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
        </StyledSelect>
      </>
    );
  }

  return (
    <StyledAutocomplete
      multiple
      options={items}
      getOptionLabel={(option) => option}
      getOptionSelected={(option, value) => option === value}
      limitTags={0}
      open={open}
      value={filtersForKey}
      disableCloseOnSelect
      getLimitTagsText={(more) => `${more} selected`}
      className={filtersForKey && filtersForKey.length > 0 ? 'has-value' : ''}
      id={`${filterKey}-input`}
      renderOption={(option, { selected }) => (
        <>
          <StyledCheckbox
            small
            checked={selected}
            data-ref={`multiselect-filter-checkbox-${option}`}
          />
          {option}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Show all"
          color="secondary"
          onClick={handleClick}
          data-ref={`multiselect-filter-text-field-${filterKey}`}
        />
      )}
      onChange={(evt, values) => onChangeFilter(values)}
      PopperComponent={(props) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
        </ClickAwayListener>
      )}
    />
  );
});

MultiSelectFilter.displayName = 'MultiSelectFilter';

export default MultiSelectFilter;

const ConnectedMultiSelectFilter = connectRefinementList(
  ({ items, attribute: filterKey, refine, currentRefinement: filtersForKey, label }) => {
    const [open, setOpen] = useState(false);
    const { workflow } = useStore((state) => state.courseStore);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
      setOpen(false);
    };

    // eslint-disable-next-line prefer-const
    let itemsOrdered = items;

    if (itemsOrdered.length > 0) {
      itemsOrdered.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (labelA < labelB) {
          return -1;
        }

        if (labelA > labelB) {
          return 1;
        }

        return 0;
      });
    }

    const options = itemsOrdered.map((item) =>
      filterKey === 'risk.level' ? capitaliseFirstChar(item.label) : item.label
    );

    return (
      <StyledAutocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option}
        getOptionSelected={(option, value) => option === value}
        limitTags={0}
        open={open}
        value={filtersForKey}
        disableCloseOnSelect
        getLimitTagsText={(more) => `${more} selected`}
        className={!isEmpty(filtersForKey) ? 'has-value' : ''}
        id={`${filterKey}-input`}
        renderOption={(option, { selected }) => (
          <>
            <StyledCheckbox
              small
              checked={selected}
              data-ref={`multiselect-filter-checkbox-${option}`}
            />
            {filterKey === CALL_STATUS_ATTRIBUTE ? callStatusLabels[workflow][option] : option}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder="Show all"
            color="secondary"
            onClick={handleClick}
            data-ref={`multiselect-filter-text-field-${filterKey}`}
          />
        )}
        onChange={(evt, values) => {
          refine(values);
        }}
        PopperComponent={(props) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
          </ClickAwayListener>
        )}
      />
    );
  }
);

export { ConnectedMultiSelectFilter };
