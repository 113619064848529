import { linkUrls } from 'linkUrls';

const CANVAS_LOGIN_URL = process.env.REACT_APP_CANVAS_LOGIN_URL || linkUrls.login;

const CANVAS_SUPPORT_URL = process.env.REACT_APP_CANVAS_SUPPORT_URL || linkUrls.support;

export default {
  CANVAS_LOGIN_URL,
  CANVAS_SUPPORT_URL
};
