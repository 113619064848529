import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';

import { useStore } from 'store';
import { Icon, ICON_TYPES } from 'components/Icon';
import {
  StyledButton,
  StyledButtonWrapper,
  StyledFilterGrid,
  StyledFilterWrapper,
  StyledTabletActions
} from './Filters.styles';
import Container from 'components/Container';
import Grid from 'components/Grid';
import MultiSelectFilter from 'components/MultiSelectFilter';
import SMSButton from 'components/SMSButton';
import SimpleButton from 'components/SimpleButton';
import SortBy from 'components/SortBy';
import TeachingPeriodSelect from 'components/TeachingPeriodSelect';
import withStickyContainer from 'hocs/withStickyContainer';

const Filters = ({ isSticky, isDrawer, onCloseDrawer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const { learningGroups, learningAdvisors, riskLevels, riskReasons, otherDetails, callStatuses } =
    useStore((state) => state.searchStore.filterOptions);
  const { showFilterDrawer, showSearchDrawer } = useStore((state) => state.drawerStore);
  const showFilters = useStore(
    (state) =>
      !(isEmpty(state.searchStore.searchResults) && isEmpty(state.searchStore.activeFilters))
  );

  useEffect(() => {
    if (!isTablet && isDrawer) {
      onCloseDrawer();
    }
  }, [isTablet, isDrawer, onCloseDrawer]);

  return (
    <Container disableGutters={!isSticky || isDrawer}>
      <StyledFilterGrid container spacing={1} drawer={isDrawer} sticky={isSticky}>
        {(isDrawer || !isTablet) && (
          <>
            <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
              <TeachingPeriodSelect label={'Week'} />
            </StyledFilterWrapper>
            {showFilters && (
              <>
                {riskLevels && riskLevels.length > 0 && (
                  <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
                    <MultiSelectFilter
                      filterKey="riskLevel"
                      items={riskLevels}
                      label={'Risk level'}
                    />
                  </StyledFilterWrapper>
                )}
                {learningGroups && learningGroups.length > 0 && (
                  <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
                    <MultiSelectFilter
                      filterKey="learningGroup"
                      items={learningGroups}
                      label={'Learning group'}
                    />
                  </StyledFilterWrapper>
                )}
                {learningAdvisors && learningAdvisors.length > 0 && (
                  <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
                    <MultiSelectFilter
                      filterKey="elaName"
                      items={learningAdvisors}
                      label={'eLa name'}
                    />
                  </StyledFilterWrapper>
                )}
                {riskReasons && riskReasons.length > 0 && (
                  <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
                    <MultiSelectFilter
                      filterKey="riskReasons"
                      items={riskReasons}
                      label={'Risk reasons'}
                    />
                  </StyledFilterWrapper>
                )}
                {otherDetails && otherDetails.length > 0 && (
                  <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
                    <MultiSelectFilter
                      filterKey="otherDetails"
                      items={otherDetails}
                      label={'Other details'}
                    />
                  </StyledFilterWrapper>
                )}
                {callStatuses && callStatuses.length > 0 && (
                  <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
                    <MultiSelectFilter
                      filterKey="callStatusLabel"
                      items={callStatuses}
                      label={'Call status'}
                    />
                  </StyledFilterWrapper>
                )}
              </>
            )}
          </>
        )}
        {isDrawer && (
          <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
            <SimpleButton
              onClick={onCloseDrawer}
              label="Hide filters"
              p={0}
              data-ref="hide-filters-drawer-button"
            />
          </StyledFilterWrapper>
        )}
        {isTablet && isSticky && !isDrawer && (
          <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
            <StyledTabletActions>
              <div>
                <StyledButton
                  size="large"
                  variant="contained"
                  disableElevation
                  onClick={showFilterDrawer}
                  startIcon={<Icon name={ICON_TYPES.FILTER} />}
                  data-ref="show-filters-drawer-button"
                >
                  <span>Filter</span>
                </StyledButton>
                <StyledButton
                  size="large"
                  variant="contained"
                  disableElevation
                  onClick={showSearchDrawer}
                  startIcon={<Icon name={ICON_TYPES.SEARCH} />}
                  data-ref="show-search-drawer-button"
                >
                  <span>Search</span>
                </StyledButton>
              </div>
              <SortBy label="Sort" />
            </StyledTabletActions>
            {!isMobile && (
              <StyledButtonWrapper>
                <SMSButton size="medium" />
              </StyledButtonWrapper>
            )}
          </Grid>
        )}
        {!isTablet && isSticky && (
          <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
            <StyledButtonWrapper>
              <SMSButton showSelectedLabel={false} size="medium" />
            </StyledButtonWrapper>
          </StyledFilterWrapper>
        )}
      </StyledFilterGrid>
    </Container>
  );
};

Filters.displayName = 'Filters';

Filters.propTypes = {
  isSticky: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onCloseDrawer: PropTypes.func
};

Filters.defaultProps = {
  isSticky: false,
  isDrawer: false,
  onCloseDrawer: () => null
};

export { Filters };

export default withStickyContainer(Filters);
