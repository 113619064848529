import lodashGet from 'lodash.get';
import isEmpty from 'lodash.isempty';

import { apiEndpoints } from 'app/constants/apiEndpoints';
import {
  transformNotes,
  transformStudentHistory,
  transformStudentHistoryData
} from 'utils/transformApiResponseUtils';
import { callStatusLabels } from 'app/constants/callStatuses';

const studentState = {
  studentById: {},
  selectedEngagementId: null,
  isFetchingStudentData: false,
  showAddNoteForm: false,
  studentRefetch: [],
  showAllStudentHistory: false,
  isRefreshingNotes: false,
  isAddingNote: false
};

const createStudentStore = (set, get) => ({
  ...studentState,

  getActiveStudent() {
    const { selectedEngagementId, studentById } = get().studentStore;

    if (!selectedEngagementId || !studentById[selectedEngagementId]) {
      return null;
    }

    return { engagementId: selectedEngagementId, ...studentById[selectedEngagementId] };
  },

  selectEngagementId(selectedStudentId) {
    set((state) => {
      state.studentStore.selectedEngagementId = selectedStudentId;
    });
  },

  fetchStudentHistory: async (engagementId) => {
    set((state) => {
      state.studentStore.isFetchingStudentData = true;
      state.studentStore.selectedEngagementId = engagementId;
    });

    const { apiRequest, handleApiError } = get().actions;
    const { workflow } = get().courseStore;

    const response = await apiRequest(apiEndpoints.studentHistory(engagementId));

    if (response.status !== 200) {
      handleApiError(response.status);
      set((state) => {
        state.studentStore.isFetchingStudentData = false;
      });

      return;
    }

    const { callStatus, ...transformedHistory } = transformStudentHistory(response.data, workflow);

    // update student data, in students list
    const students = get().studentsStore.getStudentsDataByActivePeriod();

    // only update student data if it has been loaded
    if (!isEmpty(students)) {
      const { periodId } = get().teachingPeriodsStore.selectedTeachingPeriod;

      const studentIndex = students.findIndex((student) => student.engagementId === engagementId);

      set((state) => {
        state.studentsStore.studentsByPeriod[periodId].data[studentIndex].callStatus = callStatus;
        state.studentsStore.studentsByPeriod[periodId].data[studentIndex].callStatusLabel =
          callStatusLabels[callStatus];
      });
    }

    set((state) => {
      state.studentStore.studentById[engagementId] = {
        engagementId,
        callStatus,
        ...transformedHistory
      };
      state.studentStore.isFetchingStudentData = false;
      state.studentStore.showAddNoteForm = false;
    });
  },

  // TODO: decommission original when we make the switch
  fetchStudentHistoryNewEndpoint: async (studentId) => {
    set((state) => {
      state.studentStore.isFetchingStudentData = true;
      state.studentStore.selectedEngagementId = studentId;
    });

    const { apiRequest, handleApiError } = get().actions;
    const { workflow } = get().courseStore;

    const response = await apiRequest(apiEndpoints.studentHistory(studentId));

    if (response.status !== 200) {
      handleApiError(response.status);
      set((state) => {
        state.studentStore.isFetchingStudentData = false;
        state.studentStore.isRefreshingNotes = false;
      });

      return;
    }
    const {
      history,
      summary: { student, learningGroup, mostRecentStaff }
    } = response.data;

    const transformedHistory = transformStudentHistoryData(history, workflow);

    set((state) => {
      state.studentStore.studentById[studentId] = {
        ...student,
        history: transformedHistory,
        learningGroup,
        mostRecentStaff
      };
      state.studentStore.isFetchingStudentData = false;
      state.studentStore.showAddNoteForm = false;
    });
  },

  setShowAddNoteForm() {
    set((state) => {
      state.studentStore.showAddNoteForm = true;
    });
  },

  saveNote: async ({
    note,
    callStatus,
    engagementId,
    emailFlag,
    voicemailFlag,
    week,
    resetForm
  }) => {
    const { apiRequest, handleApiError } = get().actions;
    const { username: modifiedBy } = get().authStore.userSession;

    set((state) => {
      state.studentStore.isAddingNote = true;
    });

    const response = await apiRequest({
      ...apiEndpoints.saveNote(engagementId),
      data: {
        engagementId,
        statusId: callStatus,
        notes: note,
        emailFlag,
        voicemailFlag,
        modifiedBy
      }
    });

    if (response.status !== 200) {
      handleApiError(response.status);
      return;
    }

    set((state) => {
      state.studentStore.isRefreshingNotes = true;
    });

    const studentHistory = lodashGet(get().studentStore.studentById, `${engagementId}.history`);

    const currentWeek = studentHistory.find((history) => history.period.week === week);
    const currentNotes = lodashGet(currentWeek, 'notes', []);

    const newNote = {
      noteId: response.data.noteId,
      interventionId: response.data.interventionId,
      engagementId: response.data.engagementId,
      statusId: response.data.statusId,
      createdAt: response.data.createdAt,
      emailFlag: emailFlag || false,
      voicemailFlag: voicemailFlag || false,
      modifiedBy,
      notes: note
    };

    const { workflow } = get().courseStore;

    const updatedHistory = studentHistory.map((historyObject) => {
      if (historyObject.period.week === week) {
        return {
          ...historyObject,
          callStatusLabel: callStatusLabels[response.statusId],
          callStatus: response.statusId,
          notes: transformNotes([...currentNotes, newNote], workflow)
        };
      }
      return historyObject;
    });

    if (resetForm) {
      resetForm();
    }

    if (response.data.statusId !== callStatus) {
      // This happens when the statusId has been set TO_CALL after a queued SMS has been sent
      // but the UI still indicates a latest status of "SMS pending" when the note is saved.

      set((state) => {
        state.studentStore.studentRefetch = [engagementId];
      });
    }

    set((state) => {
      state.studentStore.showAddNoteForm = false;
      state.studentStore.studentById[engagementId] = {
        ...get().studentStore.studentById[engagementId],
        callStatus: response.data.statusId,
        statusId: response.data.statusId,
        history: updatedHistory
      };
      state.studentStore.isRefreshingNotes = false;
      state.studentStore.isAddingNote = false;
    });
  },

  setShowAllStudentHistory(showAllStudentHistory) {
    set((state) => {
      state.studentStore.showAllStudentHistory = showAllStudentHistory;
    });
  }
});

export { createStudentStore };
