import { lazy } from 'react';

import { STUDENT_HISTORY_VIEW } from 'app/constants/features';
import { settings } from 'app/settings';

/* istanbul ignore next */
export const Home = lazy(() => import(/* webpackChunkName: "HomeView" */ 'views/HomeView'));
/* istanbul ignore next */
export const NotFound = lazy(() =>
  import(/* webpackChunkName: "NotFoundView" */ 'views/NotFoundView')
);
/* istanbul ignore next */
export const StudentHistory = lazy(() =>
  import(/* webpackChunkName: "StudentHistoryView" */ 'views/StudentHistoryView')
);
/* istanbul ignore next */
export const PageLayout = lazy(() =>
  import(/* webpackChunkName: "PageLayout" */ 'components/PageLayout')
);

/* istanbul ignore next */
export const SendSMS = lazy(() =>
  import(/* webpackChunkName: "SendSMSView" */ 'views/SendSMSView')
);

/* istanbul ignore next */
export const Algolia = lazy(() => import(/* webpackChunkName: "Algolia" */ 'views/AlgoliaView'));

export const Routes = {
  Home: '/',
  Error: '/error',
  Unauthorised: '/unauthorised',
  StudentHistory: '/student/',
  SendSMS: '/send-sms',
  Algolia: '/algolia'
};

export default [
  {
    path: Routes.Home,
    exact: true,
    component: Algolia
  },
  {
    path: Routes.Algolia,
    exact: true,
    component: Algolia
  },
  ...(settings[STUDENT_HISTORY_VIEW]
    ? [
        {
          path: `${Routes.StudentHistory}:engagementId`,
          exact: true,
          component: StudentHistory
        }
      ]
    : []),
  {
    path: Routes.SendSMS,
    exact: true,
    component: SendSMS
  },
  {
    path: Routes.Unauthorised,
    exact: true,
    component: PageLayout
  },
  {
    path: '*',
    exact: true,
    component: NotFound
  }
];
