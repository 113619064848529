import React, { Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import ErrorHandler from 'components/ErrorHandler';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import SessionToken from 'components/SessionToken';
import ThemeProvider from 'context/ThemeProvider';
import routes from './routes';
import Drawer from 'components/Drawer';

const history = createBrowserHistory();

function App() {
  return (
    <ThemeProvider>
      <Router history={history}>
        <SessionToken />
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Switch>
        </Suspense>
        <Drawer />
        <Modal />
        <ErrorHandler />
      </Router>
    </ThemeProvider>
  );
}

export default App;
