const isDev = process.env.NODE_ENV === 'development';

export const apiEndpoints = {
  ratData: ({ courseId, periodId, page = 1, pageSize = 500, forceFetch = false }) => ({
    method: 'get',
    url: `/engagement/course/${courseId}/period/${periodId}`,
    params: {
      page,
      pageSize,
      ...(isDev && forceFetch && { refetch: true })
    }
  }),
  teachingPeriods: (courseId) => ({
    method: 'get',
    url: `/course/${courseId}`
  }),
  saveSmsSelections: {
    method: 'put',
    url: `/engagement/sms`
  },
  twilioToken: {
    method: 'get',
    url: '/token'
  },
  studentHistory: (engagementId) => ({
    method: 'get',
    url: `/engagement/${engagementId}`
  }),
  saveNote: (engagementId) => ({
    method: 'post',
    url: `/engagement/${engagementId}/note`
  }),
  sendSms: {
    method: 'post',
    url: '/engagement/sms'
  },
  algoliaKey: {
    method: 'get',
    url: `/search/key`
  },
  course: (courseId) => ({
    method: 'get',
    url: `/unit/${courseId}`
  }),
  smsPending: (unitId) => ({
    method: 'get',
    url: `/unit/${unitId}/note/latest-requested`
  }),
  studentPII: (unitId, params) => ({
    method: 'get',
    url: `/student/unit/${unitId}`,
    params
  }),
  smsTemplatesByWorkflow: (workflow) => ({
    method: 'get',
    url: `/sms/template/workflow/${workflow}`
  })
};
