import chunk from 'lodash.chunk';
import isEmpty from 'lodash.isempty';

import {
  addRemoveStudentForSms,
  addRemoveValueFromArray,
  decodeTextMessage
} from 'utils/dataUtils';
import { apiEndpoints } from 'app/constants/apiEndpoints';
import { getFormattedPhoneNumber } from 'utils/phoneUtils';
import { SMS_REQUEST_CHUNK_SIZE } from 'app/constants/smsRequest';
import { CUSTOM_TEMPLATE, STANDARD_TEMPLATE } from 'app/constants/smsTemplates';

const transformSmsResult = (result, smsStudents) => {
  const { queued, ignored } = result || {};
  const pending =
    (queued &&
      queued.map((student) => {
        return smsStudents.find((smsStudent) => student.engagementId === smsStudent.engagementId);
      })) ||
    [];
  const failed =
    (ignored &&
      ignored.map((student) => {
        return smsStudents.find((smsStudent) => student.engagementId === smsStudent.engagementId);
      })) ||
    [];

  return { pending, failed };
};

const smsState = {
  studentsToSms: [],
  studentsToSmsChecked: [],
  isFetchingSms: false,

  smsPendingCount: undefined,

  smsStudentMap: null,
  smsSent: [],
  smsFailed: [],
  smsPending: [],
  smsQueued: [],

  customMessage: '',
  customMessageEditor: null,
  template: STANDARD_TEMPLATE,
  initialTemplate: STANDARD_TEMPLATE,
  timeframe: null
};

const createSmsStore = (set, get) => ({
  ...smsState,

  setStudentsToSms(studentsToSms) {
    set((state) => {
      state.smsStore.studentsToSms = studentsToSms;
      state.smsStore.studentsToSmsChecked = [];
    });
  },

  toggleStudentForSms(engagementId, studentId = '') {
    const { studentsToSms, studentsToSmsChecked } = get().smsStore;
    const students = addRemoveStudentForSms(studentsToSms, { engagementId, studentId });
    const studentsChecked = addRemoveValueFromArray(studentsToSmsChecked, studentId);

    set((state) => {
      state.smsStore.studentsToSms = students;
      state.smsStore.studentsToSmsChecked = studentsChecked;
    });
  },

  fetchSmsPendingCount: async () => {
    const { unitId } = get().courseStore;
    const { apiRequest, handleApiError } = get().actions;

    const response = await apiRequest(apiEndpoints.smsPending(unitId));

    if (response.status !== 200) {
      handleApiError(response.status);
      return;
    }

    set((state) => {
      state.smsStore.smsPendingCount = response.data.pending;
      if (response.data.pending === 0) {
        state.smsStore.smsPending = [];
      }
    });
  },

  sendSms: async () => {
    set((state) => {
      state.smsStore.isFetchingSms = true;
    });
    const { customMessage, getStudentsToSms, initialTemplate, template, timeframe } =
      get().smsStore;
    const { userSession } = get().authStore;
    const { teachingPeriods } = get().teachingPeriodsStore;
    const { courseKey: courseKeyUnformatted } = get().courseStore;
    const courseKey = courseKeyUnformatted ? courseKeyUnformatted.toUpperCase() : null;
    const { apiRequest } = get().actions;
    const { templates } = get().smsTemplatesStore;
    const smsStudents = getStudentsToSms();

    const unitName =
      (!isEmpty(teachingPeriods) && teachingPeriods.canvasCourseCode.toUpperCase()) || courseKey;

    // Transform student data into SMS message data
    const smsData = smsStudents.map(({ engagementId, firstName, phone }) => ({
      engagementId,
      message: decodeTextMessage(
        template === CUSTOM_TEMPLATE ? customMessage : templates[template].content,
        {
          firstName,
          elaEmail: userSession.userEmail,
          elaName: userSession.username,
          fromPhoneNumber: process.env.REACT_APP_TWILIO_FROM_NUMBER,
          unitName,
          callTimeframe: timeframe
        }
      ),
      toNumber: getFormattedPhoneNumber(phone),
      modifiedBy: userSession.username
    }));

    const smsDataChunks = chunk(smsData, SMS_REQUEST_CHUNK_SIZE);
    const responses = await Promise.all(
      smsDataChunks.map(async (data) => {
        const response = await apiRequest({ ...apiEndpoints.sendSms, data });
        response.requestData = data;
        return response;
      })
    );

    // Handle the SMS pending and failures
    const smsPending = [];
    const smsFailed = [];

    for (let i = 0; i < responses.length; i += 1) {
      const response = responses[i];
      if (response.status === 200) {
        const { pending, failed } = transformSmsResult(response.data, smsStudents);
        smsPending.push(...pending);
        smsFailed.push(...failed);
      } else {
        smsFailed.push(
          ...transformSmsResult({ ignored: response.requestData }, smsStudents).failed
        );
      }
    }

    set((state) => {
      state.smsStore.studentsToSms = [];
      state.smsStore.studentsToSmsChecked = [];
      state.smsStore.isFetchingSms = false;
      state.smsStore.smsPending = smsPending;
      state.smsStore.smsFailed = smsFailed;
      state.smsStore.smsQueued = smsPending;
      state.smsStore.customMessage = '';
      state.smsStore.customMessageEditorState = null;
      state.smsStore.template = initialTemplate;

      // Force refetch on student and smsStudents views, to reflect sms events
      state.studentsStore.forceStudentsRefetch = true;
      state.studentStore.studentRefetch = smsPending;
    });
  },

  clearStudentsToSms() {
    set((state) => {
      state.smsStore.studentsToSms = [];
      state.smsStore.studentsToSmsChecked = [];
    });
  },

  setTemplate(template) {
    set((state) => {
      state.smsStore.template = template;
    });
  },

  setTimeframe(timeframe) {
    set((state) => {
      state.smsStore.timeframe = timeframe;
    });
  },

  setCustomMessage({ customMessage, customMessageEditor }) {
    set((state) => {
      state.smsStore.customMessage = customMessage;
      state.smsStore.customMessageEditor = customMessageEditor;
    });
  },

  resetCustomMessage() {
    const { initialTemplate } = get().smsStore;
    set((state) => {
      state.smsStore = {
        ...get().smsStore,
        customMessage: '',
        customMessageEditor: null,
        template: initialTemplate
      };
    });
  },

  getStudentsToSms() {
    const { studentsToSms } = get().smsStore;
    const { studentsPII } = get().studentsPIIStore;

    // Find student data via PII data when it exists
    if (!isEmpty(studentsPII)) {
      return studentsToSms.reduce((students, selectedStudent) => {
        const studentIndex = studentsPII.findIndex(
          (student) => student.studentId === selectedStudent.studentId
        );

        if (studentIndex >= 0) {
          const { firstName, lastName, studentId, phone } = studentsPII[studentIndex];

          students.push({
            firstName,
            lastName,
            name: `${firstName} ${lastName}`,
            engagementId: selectedStudent.engagementId,
            studentId,
            phone
          });
        }

        return students;
      }, []);
    }

    return [];
  },

  getSmsQueuedNames() {
    const { smsQueued } = get().smsStore;
    if (isEmpty(smsQueued)) {
      return [];
    }

    return smsQueued.reduce((names, student) => {
      names.push(`${student.firstName} ${student.lastName}`);

      return names;
    }, []);
  }
});

export { createSmsStore };
