import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import isEmpty from 'lodash.isempty';

import { StyledSortBy } from './SortBy.styles';
import { isJsonString } from 'utils/dataUtils';
import { useStore } from 'store';

const SortBy = ({ label }) => {
  const { setSortingOption, sortBy, sortingOptions, searchResults } = useStore(
    (state) => state.searchStore
  );

  const noSearchResults = isEmpty(searchResults);

  if (!sortBy) {
    return null;
  }

  const changeSortingOption = (event, newSortingOption) => {
    const optionToSet = isJsonString(newSortingOption) ? JSON.parse(newSortingOption) : null;

    if (optionToSet) {
      setSortingOption(optionToSet);
    }
  };

  return (
    <StyledSortBy className="sortby-toggle">
      <InputLabel htmlFor="sortby">{label}</InputLabel>
      <ToggleButtonGroup
        size="medium"
        value={JSON.stringify(sortBy)}
        exclusive
        onChange={changeSortingOption}
        data-ref={'sort-by-toggle'}
        id="sortby"
      >
        {sortingOptions.map((option, index) => {
          return (
            <ToggleButton
              key={index}
              value={JSON.stringify(option)}
              size="large"
              disabled={noSearchResults}
            >
              {option.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </StyledSortBy>
  );
};

SortBy.propTypes = {
  isSticky: PropTypes.string,
  label: PropTypes.string
};

SortBy.defaultProps = {
  label: 'Sort by'
};

export default SortBy;
