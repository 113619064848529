import InfoIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import React from 'react';

import StyledTooltip, { TooltipContent } from './Tooltip.styles';

const Tooltip = ({ children, content, disabled, interactive, width }) => {
  if (disabled) {
    return children;
  }
  return (
    <StyledTooltip
      title={
        <TooltipContent width={width}>
          <div data-ref="tooltip-content" dangerouslySetInnerHTML={{ __html: content }}></div>
        </TooltipContent>
      }
      interactive={interactive}
      enterTouchDelay={200}
      leaveTouchDelay={3000}
    >
      {children || <InfoIcon className="info-icon" />}
    </StyledTooltip>
  );
};

Tooltip.defaultProps = {
  disabled: false,
  interactive: false
};

Tooltip.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  interactive: PropTypes.bool,
  width: PropTypes.number
};

export default Tooltip;
