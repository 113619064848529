import { text } from 'text';

import { ICON_TYPES } from 'components/Icon';
import Links from './links';

export const errorMessages = {
  genericError: {
    icon: ICON_TYPES.GENERIC_ERROR,
    title: text.errorMessages.genericError.title,
    message: text.errorMessages.genericError.message,
    actions: [
      {
        primary: true,
        url: Links.CANVAS_LOGIN_URL,
        label: text.actionLabels.returnToLms
      },
      {
        url: Links.CANVAS_SUPPORT_URL,
        label: text.actionLabels.contactSupport
      }
    ]
  },
  unauthorisedAccess: {
    icon: ICON_TYPES.NO_ACCESS,
    title: text.errorMessages.unauthorisedAccess.title,
    message: text.errorMessages.unauthorisedAccess.message,
    actions: [
      {
        primary: true,
        url: Links.CANVAS_LOGIN_URL,
        label: text.actionLabels.gotoLms
      },
      {
        url: Links.CANVAS_SUPPORT_URL,
        label: text.actionLabels.contactSupport
      }
    ]
  },
  noSessionId: {
    icon: ICON_TYPES.NO_CONNECTION,
    title: text.errorMessages.noSessionId.title,
    message: text.errorMessages.noSessionId.message,
    actions: [
      {
        primary: true,
        url: Links.CANVAS_LOGIN_URL,
        label: text.actionLabels.returnToLms
      },
      {
        url: Links.CANVAS_SUPPORT_URL,
        label: text.actionLabels.contactSupport
      }
    ]
  },
  decodeSessionIdError: {
    icon: ICON_TYPES.GENERIC_ERROR,
    title: text.errorMessages.decodeSessionIdError.title,
    message: text.errorMessages.decodeSessionIdError.message,
    actions: [
      {
        primary: true,
        url: Links.CANVAS_SUPPORT_URL,
        label: text.actionLabels.contactSupport
      }
    ]
  },
  insufficientPrivileges: {
    icon: ICON_TYPES.NO_ACCESS,
    title: text.errorMessages.insufficientPrivileges.title,
    message: text.errorMessages.insufficientPrivileges.message,
    actions: [
      {
        primary: true,
        url: Links.CANVAS_LOGIN_URL,
        label: text.actionLabels.gotoLms
      },
      {
        url: Links.CANVAS_SUPPORT_URL,
        label: text.actionLabels.contactSupport
      }
    ]
  },
  noTeachingPeriods: {
    icon: ICON_TYPES.NO_DATA,
    title: text.errorMessages.noTeachingPeriods.title,
    message: text.errorMessages.noTeachingPeriods.message,
    actions: [
      {
        primary: true,
        url: Links.CANVAS_LOGIN_URL,
        label: text.actionLabels.gotoLms
      },
      {
        url: Links.CANVAS_SUPPORT_URL,
        label: text.actionLabels.contactSupport
      }
    ]
  },
  sessionExpired: {
    icon: ICON_TYPES.TIME_OFF,
    title: text.errorMessages.sessionExpired.title,
    message: text.errorMessages.sessionExpired.message,
    actions: [
      {
        primary: true,
        url: Links.CANVAS_LOGIN_URL,
        label: text.actionLabels.gotoLms
      }
    ]
  },
  sessionExpiryWarning: {
    icon: ICON_TYPES.ALARM,
    title: text.errorMessages.sessionExpiryWarning.title,
    message: text.errorMessages.sessionExpiryWarning.message,
    actions: [
      {
        primary: true,
        label: text.actionLabels.confirm
      },
      {
        url: Links.CANVAS_LOGIN_URL,
        label: text.actionLabels.gotoLms
      }
    ]
  },
  saveNoteError: {
    icon: ICON_TYPES.ERROR_EXCLAMATION,
    title: text.errorMessages.saveNoteError.title,
    message: text.errorMessages.saveNoteError.message,
    actions: [
      {
        primary: true,
        label: text.actionLabels.ok
      }
    ]
  },
  sendSmsError: {
    icon: ICON_TYPES.ERROR_EXCLAMATION,
    title: text.errorMessages.sendSmsError.title,
    message: text.errorMessages.sendSmsError.message,
    actions: [
      {
        primary: true,
        label: text.actionLabels.ok
      },
      {
        url: Links.CANVAS_SUPPORT_URL,
        label: text.actionLabels.contactSupport
      }
    ]
  }
};
