const HIGH = 1;
const MEDIUM = 2;
const LOW = 3;
const DORMANT = 4;

export const riskLevelLabels = {
  [HIGH]: 'High risk',
  [MEDIUM]: 'Medium risk',
  [LOW]: 'Low risk',
  [DORMANT]: 'Dormant'
};

export const riskLevelLabelsShort = {
  [HIGH]: 'High',
  [MEDIUM]: 'Med',
  [LOW]: 'Low',
  [DORMANT]: 'Dor'
};

export default {
  HIGH,
  MEDIUM,
  LOW,
  DORMANT
};
