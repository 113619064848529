const helpMessages = {
  studentDataNotification: `Aim to send an SMS for the students that require an intervention no later than <strong>05:00pm Thursday of each teaching week</strong>. You may call your students immediately after sending the SMS, you do not need to wait until after 5pm Thursday. <strong><a href="${process.env.REACT_APP_TRAINING_URL}" target="_blank" rel="noopener noreferrer">Find out more info</a></strong>`,
  riskUnits:
    'This shows all of the units that are at risk during this week. Other learning advisors from these units may contact the student too.',
  disabledSmsSelection:
    'You are unable to select a student for SMS after the cutoff time 5pm Thursday',
  disabledSmsSave: 'There are no students selected to send an SMS to',
  callsOffline: 'Calling currently offline - try again soon',
  callsDisabled: 'Voice calling is not available for this unit',
  callDialing: 'Ringing',
  callInProgress: 'Call in progress',
  callDisconnected: 'Call ended',
  callsOnline: 'Calling online',
  phoneNumberInvalid: 'Invalid phone number',
  callingError:
    'An error occurred while initialising the calling service. Make sure your browser has access to your microphone and <a href="javascript:window.location.href=window.location.href">refresh the page</a>',
  changeCallStatus: `<p>Change a call status so you can keep track of where you're at with calling a student. We also use these to determine how you are remunerated for the program. <a href="${process.env.REACT_APP_CALL_STATUS_HELP_URL}" target="_blank" title="Find out more about call statuses">More info</a></p><ul><li><strong>To call</strong>  - SMS has been sent and the student is ready to call</li><li><strong>Retry later</strong> - will try to get in contact with student later</li><li><strong>Contacted</strong> - Contact was made with the student</li><li><strong>No contact</strong> - Attempted to contact the student but no contact was made</li></ul>`,
  disabledCallStatusOption: 'You are unable choose the current status',
  howToMakeACall: `You can call the student from this page. Make sure your browser has access to your mic device. If you want to check, click "show device options" <a href="${process.env.REACT_APP_CALL_STATUS_HELP_URL}" target="_blank" title="Find out more about making calls">More info</a>`,
  maxSmsSelections: (max) => `You can only select a maximum of ${max} students to send an SMS to`,
  noStudentData: 'There is no data for this teaching week. Select another week.',
  noSearchResultsSearchTerm: 'There are no results for your search term.',
  noSearchResultsFilters: 'There are no results from your filter selection.',
  noSearchResultsSearchTermAndFilters:
    'There are no results for your search term and filter selection.',
  muteCall: 'Mute mic',
  unMuteCall: 'Unute mic',
  addingNotesToACall:
    'After completing a call, make sure you document the details here. Make sure you change the call status to be appropriately remunerated for your effort.',
  noStatusSelection: 'You must choose a call status or uncheck this option',
  invalidAustralianMobileNumber:
    "Only student's with a valid Australian mobile phone number can be sent an SMS",
  smsPending:
    'An SMS has been scheduled to be sent to this student. Click to refresh for an updated call status.',
  smsFromPreview: 'OES'
};

export default helpMessages;
