import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import { useStore } from 'store';
import { StyledSelect, StyledSelectValue } from 'styles';
import { isJsonString } from 'utils/dataUtils';

const TeachingPeriodSelect = ({ label }) => {
  const [open, setOpen] = useState(false);

  const { selectTeachingPeriod, selectedTeachingPeriod, teachingPeriods } = useStore(
    (state) => state.teachingPeriodsStore
  );
  const { resetActiveFilters, setIsSearching } = useStore((state) => state.searchStore);

  const changeTeachingPeriod = (newTeachingPeriod) => {
    setIsSearching(true);
    resetActiveFilters();

    selectTeachingPeriod(JSON.parse(newTeachingPeriod));
  };

  return (
    <>
      <InputLabel htmlFor="TeachingPeriodSelect">{label}</InputLabel>
      <StyledSelect
        fullWidth
        value={JSON.stringify(selectedTeachingPeriod)}
        id="TeachingPeriodSelect"
        displayEmpty
        open={open}
        onChange={(e) => changeTeachingPeriod(e.target.value)}
        onClose={() => setOpen(false)}
        renderValue={(selected) => {
          const selectedOption = selected && isJsonString ? JSON.parse(selected) : null;
          return (
            <StyledSelectValue onClick={() => setOpen(true)} aria-label="TeachingPeriodSelect">
              {selectedOption
                ? `TP ${selectedOption.tp} - Week ${selectedOption.week}`
                : 'Select week'}
            </StyledSelectValue>
          );
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {teachingPeriods.periods &&
          teachingPeriods.periods.map((option) => (
            <MenuItem key={option.periodId} value={JSON.stringify(option)}>
              <ListItemText primary={`TP ${option.tp} - Week ${option.week}`} />
            </MenuItem>
          ))}
      </StyledSelect>
    </>
  );
};

TeachingPeriodSelect.propTypes = {
  label: PropTypes.string
};

export default TeachingPeriodSelect;
