import { apiEndpoints } from 'app/constants/apiEndpoints';
import { getErrorMessage } from '../utils/apiUtils';
import { errorMessages } from '../app/constants/errorMessages';
import { SMS_WORKFLOW_PHONE_CALL } from '../app/constants/workflows';

const createCourseStore = (set, get) => ({
  isFetchingCourseData: false,
  courseName: null,
  courseKey: null,
  unitId: null,
  filterDetails: null,
  periods: null,
  staff: null,
  workflow: null,

  fetchCourseData: async () => {
    set((state) => {
      state.courseStore.isFetchingCourseData = true;
    });

    const { courseId } = get().authStore.userSession;

    if (!courseId) {
      // TODO: handle what to do here
      return;
    }

    const { apiRequest } = get().actions;
    const response = await apiRequest(apiEndpoints.course(courseId));

    if (response.status === 404) {
      set((state) => {
        state.teachingPeriodsStore.isFetchingTeachingPeriods = false;
        if (get().algoliaStore.isKeyAvailable) {
          state.error = errorMessages.noTeachingPeriods;
        }
      });
      return;
    }

    if (response.status !== 200) {
      const error = getErrorMessage(response.status);
      set((state) => {
        state.teachingPeriodsStore.isFetchingTeachingPeriods = false;
        state.error = error;
      });
      return;
    }

    set((state) => {
      state.courseStore.unitId = response.data.termId;
      state.courseStore.courseName = response.data.termLabel;
      state.courseStore.courseKey = response.data.termKey;
      state.courseStore.periods = response.data.periods;
      state.courseStore.staff = response.data.staff;
      state.courseStore.filterDetails = response.data.detailTagTerms;
      state.courseStore.workflow = response.data.termAttributes.workflow ?? SMS_WORKFLOW_PHONE_CALL;
    });

    get().algoliaStore.initSearchState();
    await get().smsStore.fetchSmsPendingCount();

    set((state) => {
      state.courseStore.isFetchingCourseData = false;
    });

    await get().studentsPIIStore.fetchStudentsData();
  },

  getTeachingPeriodLabel(periodValue) {
    const { periods } = get().courseStore;
    const period = periods.find((item) => item.periodId === periodValue);
    return `TP ${period.tp} - Week ${period.week}`;
  },

  getFilterDetails() {
    return get().courseStore.filterDetails;
  }
});

export { createCourseStore };
