import isEmpty from 'lodash.isempty';

export const addRemoveValueFromArray = (targetArray, value) => {
  return targetArray.includes(value)
    ? targetArray.filter((arrayVal) => arrayVal !== value)
    : [...targetArray, value];
};

export const removeValueFromArray = (targetArray, value) => {
  return targetArray.includes(value)
    ? targetArray.filter((arrayVal) => arrayVal !== value)
    : targetArray;
};

export const generateArrayFromKeys = (keys, items) => {
  const sets = {};
  /* eslint-disable-next-line no-plusplus  */
  for (let index = 0; index < items.length; index++) {
    const element = items[index];
    keys.forEach((key) => {
      const elementToAdd = element[key];

      sets[key] = sets[key] || new Set();
      if (!elementToAdd) {
        return;
      }
      if (Array.isArray(elementToAdd)) {
        elementToAdd.forEach((val) => sets[key].add(val));
      } else {
        sets[key].add(element[key]);
      }
    });
  }

  return Object.entries(sets).reduce((acc, [key, value]) => {
    // eslint-disable-next-line no-param-reassign
    acc[key] = Array.from(value).sort();
    return acc;
  }, {});
};

export const removeDuplicatesFromArray = (array1, array2) => {
  const uniqueArray1 = array1 && array1.filter((val) => array2 && array2.indexOf(val) === -1);
  const uniqueArray2 = array2 && array2.filter((val) => array1 && array1.indexOf(val) === -1);

  return uniqueArray1.concat(uniqueArray2);
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getStudentsSavedText = (exportComplete, number) =>
  `${number === 0 ? 'No' : number} student${number !== 1 ? 's' : ''} ${
    exportComplete ? 'sent' : 'saved for'
  } SMS`;

export const getStudentsToCallText = (number) => `${number === 0 ? 'No' : number} To call`;
export const getSmsPendingText = (number) => `${number === 0 ? 'No' : number} SMS pending`;
export const getSmsFailedLength = (number) => `${number === 0 ? 'No' : number} SMS failed`;

export const getObjectByPropertyValue = (array, property, value) => {
  if (array && property && value) {
    /* eslint-disable-next-line no-plusplus  */
    for (let i = 0; i < array.length; i++) {
      if (array[i][property] === value) {
        return array[i];
      }
    }
  }
  return null;
};

export const replaceStringWithVars = (string, mapObj) => {
  const regex = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return string && string.replace(regex, (matched) => mapObj[matched] || '');
};

const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export const decodeTextMessage = (string, mapObj) => {
  const replaceDynamicVariables = replaceStringWithVars(String(string), mapObj);
  const replaceBreakTags = replaceDynamicVariables.replace(/<br[^>]*>/gi, '\n');
  // const replaceHtmlTags = replaceBreakTags.replace(/(<([^>]+)>)/gi, '');
  const replaceHtmlTags = replaceBreakTags.replace(/<[^>]*>/gi, '');
  return htmlDecode(replaceHtmlTags);
};

export const isIterable = (obj) => {
  if (obj === null) return false;
  return typeof obj[Symbol.iterator] === 'function';
};

export const addRemoveStudentForSms = (students, student) => {
  return !isEmpty(students.find((selectStudent) => selectStudent.studentId === student.studentId))
    ? students.filter((selectStudent) => selectStudent.studentId !== student.studentId)
    : [...students, student];
};
