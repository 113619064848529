const UNIT_COORDINATOR = 'UC';
const LEVEL_UNIT_COORDINATOR = 'LUC';
const E_LEARNING_ADVISOR = 'eLA';
const ONLINE_LEARNING_ADVISOR = 'OLA';
const PROGRAM_DIRECTOR = 'PD';
const ACCOUNT_ADMIN = 'Account Admin';
const ACADEMIC_ADMIN = 'Academic Admin';
const PROGRAM_DIRECTOR_LEVEL_UNIT_COORDINATOR_ADMIN = 'PD/LUC Admin';
const UNIT_COORDINATOR_E_LEARNING_ADVISOR_ADMIN = 'UC/eLA Admin';
const ACADEMIC_ADMIN_PLUS = 'Academic Admin+';
const DATA_ANALYSIS = 'Data Analysis';
const DESIGNER_ENROLLMENT = 'DesignerEnrollment';
const MARKING_ASSISTANT = 'Marking Assistant';

export const allowedUserRoles = [
  UNIT_COORDINATOR,
  LEVEL_UNIT_COORDINATOR,
  E_LEARNING_ADVISOR,
  ONLINE_LEARNING_ADVISOR,
  PROGRAM_DIRECTOR,
  ACCOUNT_ADMIN,
  ACADEMIC_ADMIN,
  PROGRAM_DIRECTOR_LEVEL_UNIT_COORDINATOR_ADMIN,
  UNIT_COORDINATOR_E_LEARNING_ADVISOR_ADMIN,
  ACADEMIC_ADMIN_PLUS,
  DATA_ANALYSIS,
  DESIGNER_ENROLLMENT,
  MARKING_ASSISTANT
];
