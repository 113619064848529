/* eslint-disable prefer-destructuring */
import Button from '@material-ui/core/Button';
import React from 'react';
import { emitCustomEvent } from 'react-custom-events';

import { SMS_REQUESTED, NO_CALL_STATUS } from 'app/constants/callStatuses';
import Tooltip from 'components/Tooltip';
import { calculateDaysBetweenDates } from 'utils/dateUtils';
import { Icon, ICON_TYPES } from 'components/Icon';
import {
  COPY_PHONE_NUMBER_EVENT,
  REFRESH_STUDENT_HISTORY,
  SEND_SMS_EVENT
} from 'app/constants/events';
import { helpMessages } from 'app/constants/helpMessages';
import { isValidMobileNumber } from 'utils/phoneUtils';

const getPersonalInfoList = (
  {
    studentLmsId,
    state,
    phone,
    studentEmail,
    personalEmail,
    withLabel = true,
    fullName,
    callStatus,
    firstName,
    lastName
  },
  copyPhoneNumber,
  sendSms
) => {
  const phoneNumberValid = isValidMobileNumber(phone);
  const smsPending = callStatus === SMS_REQUESTED;

  const listArray = [
    {
      icon: <Icon name={ICON_TYPES.FINGERPRINT} />,
      text: withLabel ? 'Student ID' : studentLmsId,
      ...(withLabel && { secondaryText: studentLmsId })
    },
    {
      icon: <Icon name={ICON_TYPES.LOCATION_ON} />,
      text: withLabel ? 'Location' : state,
      ...(withLabel && { secondaryText: state })
    },
    {
      icon: <Icon name={ICON_TYPES.PHONE} />,
      text: withLabel ? 'Phone no.' : phone,
      ...(withLabel && {
        secondaryText: copyPhoneNumber ? (
          <span className="phone-number">
            {phone}
            <Icon
              name={ICON_TYPES.COPY_PHONE_NUMBER}
              title="Copy phone number"
              onClick={() => emitCustomEvent(COPY_PHONE_NUMBER_EVENT, phone)}
            />
          </span>
        ) : (
          phone
        )
      }),
      horizontal: true,
      ...(sendSms && {
        secondaryAction: (
          <Tooltip
            content={
              smsPending ? helpMessages.smsPending : helpMessages.invalidAustralianMobileNumber
            }
            disabled={smsPending ? false : phoneNumberValid}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="button"
                onClick={() =>
                  emitCustomEvent(smsPending ? REFRESH_STUDENT_HISTORY : SEND_SMS_EVENT, {
                    fullName,
                    firstName,
                    lastName,
                    phoneNumber: phone
                  })
                }
                className={`send-sms ${smsPending ? 'sms-pending' : ''}`}
                size="small"
                disabled={smsPending ? false : !phoneNumberValid}
                startIcon={smsPending ? <Icon name={ICON_TYPES.REFRESH} /> : null}
              >
                {smsPending ? 'SMS pending' : 'Send SMS'}
              </Button>
            </div>
          </Tooltip>
        )
      })
    },
    {
      icon: <Icon name={ICON_TYPES.EMAIL} />,
      text: withLabel
        ? 'Student email'
        : `<a href="mailto:${studentEmail}" target="_blank">${studentEmail}</a>`,
      ...(withLabel && {
        secondaryText: `<a href="mailto:${studentEmail}" target="_blank">${studentEmail}</a>`
      })
    },
    {
      icon: <Icon name={ICON_TYPES.CONTACT_MAIL} />,
      text: withLabel
        ? 'Personal email'
        : `<a href="mailto:${personalEmail}" target="_blank">${personalEmail}</a>`,
      ...(withLabel && {
        secondaryText: `<a href="mailto:${personalEmail}" target="_blank">${personalEmail}</a>`
      })
    }
  ];

  return listArray;
};

const getIconByName = (iconName) => <Icon name={iconName} />;

const getOverviewLabel = (detail) => {
  let useStudentTypeIcon = false;
  let { termLabel, tagValue } = detail;

  if (detail.termAttributes.valueType === 'boolean') {
    tagValue = '';
  }

  if (detail.termAttributes.valueType === 'number' && tagValue === 0) {
    tagValue = '0';
  }

  // Formatting for New or Continued student tags
  if (detail.termLabel === 'New or Continuing') {
    useStudentTypeIcon = true;
    termLabel = 'Student Type';
  }

  return {
    icon: useStudentTypeIcon
      ? getIconByName(ICON_TYPES.DIRECTIONS_WALK)
      : getIconByName(detail.termAttributes.icon),
    text: termLabel,
    secondaryText: tagValue
  };
};

const mapDetailsToOverviewLabels = (details) => details.map((detail) => getOverviewLabel(detail));

const getLearningInfoList = ({ learningGroup, elaName, tags }) => {
  const listArray = [
    {
      icon: getIconByName(ICON_TYPES.GROUP),
      text: 'Learning Group',
      secondaryText: learningGroup
    },
    {
      icon: getIconByName(ICON_TYPES.LOCAL_LIBRARY),
      text: 'Learning advisor',
      secondaryText: elaName
    },
    ...mapDetailsToOverviewLabels(tags)
  ];

  return listArray;
};

const getExtraDetailsList = ({ tags }) => {
  return mapDetailsToOverviewLabels(tags);
};

const getActivityList = (avgWklyCanvasMins, lastAccessedDate) => {
  const daysSinceLastLogin = calculateDaysBetweenDates(lastAccessedDate);

  const listArray = [{ text: `${avgWklyCanvasMins} average minutes active` }];

  if (daysSinceLastLogin != null && daysSinceLastLogin > 0) {
    listArray.unshift({
      text: `Last logged into canvas ${daysSinceLastLogin} day${
        daysSinceLastLogin === 1 ? '' : 's'
      } ago`
    });
  }

  return listArray;
};

const useStudentDetails = (studentData) => {
  const {
    studentId,
    studentLmsId,
    state,
    phone,
    studentEmail,
    personalEmail,
    avgWklyLmsMins,
    lastAccessedDate
    // newOrCont
  } = studentData;
  return {
    otherDetailsList: {},
    studentInfoList: getPersonalInfoList({
      studentId,
      studentLmsId,
      state,
      phone,
      studentEmail,
      personalEmail,
      withLabel: false
    }),
    activityList: getActivityList(avgWklyLmsMins, lastAccessedDate)
  };
};

const getLatestStatus = (studentHistory) => {
  let status = NO_CALL_STATUS;

  if (studentHistory.length > 0) {
    const notes = studentHistory[0].notes;
    status = notes.length === 0 ? NO_CALL_STATUS : notes[notes.length - 1].statusId;
  }

  return status;
};

const compileStudentOverviewLists = ({
  studentLmsId,
  state,
  phone,
  studentEmail,
  personalEmail,
  firstName,
  lastName,
  copyPhoneNumber,
  sendSms,
  history,
  mostRecentStaff,
  learningGroup,
  tags
}) => {
  const collatedTags = [...tags, ...(history[0].tags || [])];
  const learnGroupTags = collatedTags.filter(
    (tag) => tag.termAttributes.label_group === 'learning'
  );
  const additionalGroupTags = collatedTags.filter(
    (tag) => tag.termAttributes.label_group === 'additional'
  );
  return {
    personalInfo: getPersonalInfoList(
      {
        studentLmsId,
        state,
        phone,
        studentEmail,
        personalEmail,
        fullName: `${firstName} ${lastName}`,
        callStatus: getLatestStatus(history),
        firstName
      },
      copyPhoneNumber,
      sendSms
    ),
    learningInfo: getLearningInfoList({
      learningGroup: learningGroup.groupName,
      elaName: mostRecentStaff.fullName,
      tags: learnGroupTags
    }),
    extraDetails: getExtraDetailsList({
      tags: additionalGroupTags
    })
  };
};

const updatedDetailIconsMap = {
  studyLoad: <Icon name={ICON_TYPES.MENU_BOOK} />,
  eap: <Icon name={ICON_TYPES.ACCESSIBILITY_NEW} />,
  unitsCompleted: <Icon name={ICON_TYPES.CHECK_CIRCLE_OUTLINE} />,
  assignmentsMissed: <Icon name={ICON_TYPES.LIBRARY_BOOKS} />,
  newStudent: <Icon name={ICON_TYPES.NEW_RELEASES} />,
  nesb: <Icon name={ICON_TYPES.LANGUAGE} />,
  studentId: <Icon name={ICON_TYPES.FINGERPRINT} />,
  location: <Icon name={ICON_TYPES.LOCATION_ON} />,
  phone: <Icon name={ICON_TYPES.PHONE} />,
  studentEmail: <Icon name={ICON_TYPES.EMAIL} />,
  personalEmail: <Icon name={ICON_TYPES.CONTACT_MAIL} />,
  elaName: <Icon name={ICON_TYPES.LOCAL_LIBRARY} />,
  studentType: <Icon name={ICON_TYPES.DIRECTIONS_WALK} />,
  learningGroup: <Icon name={ICON_TYPES.GROUP} />,
  unitRepeater: <Icon name={ICON_TYPES.REPEAT} />,
  nationalCommitment: <Icon name={ICON_TYPES.STAR} />,
  continuing: <Icon name={ICON_TYPES.CONTINUING_STUDENT} />
};

export {
  updatedDetailIconsMap,
  getPersonalInfoList,
  compileStudentOverviewLists,
  useStudentDetails
};
