import lodashGet from 'lodash.get';

import {
  CALL_SCHEDULED,
  NO_CALL_STATUS,
  SMS_FAILED,
  SMS_REQUESTED,
  callStatusLabels
} from 'app/constants/callStatuses';
import { generateArrayFromKeys, getObjectByPropertyValue } from 'utils/dataUtils';
import { errorMessages } from 'app/constants/errorMessages';
import { riskLevelLabels } from 'app/constants/riskLevels';
import { SMS_WORKFLOW_PHONE_CALL } from 'app/constants/workflows';

export const getDetailText = (key, value) => {
  switch (key) {
    case 'newOrCont':
      return value && value.toLowerCase().includes('cont') ? 'Continuing student' : 'New student';
    case 'studyLoad':
      return `Study load ${value}`;
    case 'assessmentsMissed':
      return value > 0 ? `Assessments missed` : null;
    case 'unitsCompleted': {
      return value > 0 ? `${value} unit${value !== 1 ? 's' : ''} completed` : null;
    }
    case 'eap':
      return value ? 'EAP student' : null;
    case 'nesb':
      return value ? 'Non-english speaking background' : null;
    case 'nationalCommitment':
      return value ? 'National commitment' : null;

    default:
      return null;
  }
};

export const getOtherDetails = (studentData) => {
  if (typeof studentData === 'object' && studentData !== null) {
    return Object.entries(studentData).reduce((acc, [key, value]) => {
      const detail = getDetailText(key, value);
      if (detail) {
        acc.push(detail);
      }
      return acc;
    }, []);
  }
  return [];
};

export const getAvailableFilters = (data) => {
  if (!data) {
    return null;
  }

  // TODO: This ideally is configurable, based on partner
  const attributeKeys = ['elaName', 'learningGroup', 'riskReasons', 'riskStatus', 'otherDetails'];

  const { elaName, riskStatus, learningGroup, riskReasons, otherDetails } = generateArrayFromKeys(
    attributeKeys,
    data
  );

  return {
    learningAdvisors: elaName || [],
    learningGroups: learningGroup || [],
    riskLevels: (riskStatus && riskStatus.map((status) => riskLevelLabels[status])) || [],
    riskReasons: riskReasons || [],
    otherDetails: (otherDetails && otherDetails.filter((val) => !val.includes('completed'))) || [],
    callStatuses: Object.values(callStatusLabels[SMS_WORKFLOW_PHONE_CALL]).filter((val) => val)
  };
};

export const getEngagementIdsByStatus = (data, status) => {
  return data && Array.isArray(data)
    ? data.filter((student) => student.callStatus === status).map((student) => student.engagementId)
    : [];
};

export const getActiveElaFilter = (studentData, userLoginId) => {
  const studentEntry = getObjectByPropertyValue(studentData, 'elaEmail', userLoginId);

  return studentEntry ? [{ elaName: studentEntry.elaName }] : [];
};

export const transformIndividualStudent = (studentRecord) => {
  if (!studentRecord) {
    return {};
  }

  const {
    engagementId,
    riskId,
    riskReason,
    unitsHighRisk,
    avgWklyCanvasMins,
    learningGroup,
    elaName,
    lastAccessedDate,
    assessmentsMissed,
    interventions,
    exported,
    unitRepeater,
    unitsCompleted,
    studyLoad,
    elaEmail,
    state,
    firstName,
    lastName,
    studentUuid,
    studentId,
    phone,
    personalEmail,
    studentEmail,
    eap,
    nationalCommitment,
    nesb,
    newOrCont
  } = studentRecord;

  const fullName = `${firstName ? `${firstName} ` : ''}${lastName || ''}`;
  const otherDetails = getOtherDetails(studentRecord);

  return {
    fullName,
    firstName,
    lastName,
    eap: eap || false,
    nationalCommitment: nationalCommitment || false,
    nesb: nesb || false,
    unitRepeater: unitRepeater || false,
    smsExported: exported || false,
    ...(interventions && {
      interventionId: lodashGet(interventions, 'interventionId'),
      callStatus: lodashGet(interventions, 'statusId', NO_CALL_STATUS),
      callStatusLabel:
        callStatusLabels[SMS_WORKFLOW_PHONE_CALL][
          lodashGet(interventions, 'statusId', NO_CALL_STATUS)
        ]
    }),
    ...(otherDetails && otherDetails.length > 0 && { otherDetails }),
    ...(learningGroup && { learningGroup }),
    ...(elaName && { elaName }),
    ...(elaEmail && { elaEmail }),
    ...(lastAccessedDate && { lastAccessedDate }),
    ...(assessmentsMissed && { assessmentsMissed }),
    ...(unitsCompleted && { unitsCompleted }),
    ...(studyLoad && { studyLoad }),
    ...(studentUuid && { studentUuid }),
    ...(studentId && { studentId }),
    ...(phone && { phone }),
    ...(personalEmail && { personalEmail }),
    ...(studentEmail && { studentEmail }),
    ...(riskId && {
      riskLevel: riskLevelLabels[riskId],
      riskStatus: riskId,
      riskReasons: riskReason,
      riskReasonsNumber: riskReason ? riskReason.length : 0
    }),
    ...(unitsHighRisk && {
      highRiskUnits: unitsHighRisk,
      highRiskUnitsNumber: unitsHighRisk.length
    }),
    ...(engagementId && { engagementId }),
    ...(state && { state: state.toUpperCase() }),
    ...(avgWklyCanvasMins && { avgWklyCanvasMins }),
    ...(newOrCont && { newOrCont })
  };
};

export const transformStudentData = (data, userLoginId) => {
  const transformedData =
    data &&
    data.map((studentRecord) => {
      const { student, ...rest } = studentRecord;
      return transformIndividualStudent({ ...student, ...rest });
    });

  const filterOptions = getAvailableFilters(transformedData);

  return {
    filterOptions,
    // TODO: ideally rename 'data' to something more appropriate
    // current studentData.data.[periodId].data.... is not ideal
    data: transformedData,
    activeFilters: getActiveElaFilter(transformedData, userLoginId),
    smsSent: getEngagementIdsByStatus(transformedData, CALL_SCHEDULED),
    smsPending: getEngagementIdsByStatus(transformedData, SMS_REQUESTED),
    smsFailed: getEngagementIdsByStatus(transformedData, SMS_FAILED)
  };
};

const convertPeriodToString = (period) => {
  return Object.values({
    year: String(period.year),
    tp: period.tp.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
    week: period.week.toLocaleString(undefined, { minimumIntegerDigits: 2 })
  }).join('');
};

export const transformTeachingPeriods = (data) => {
  const { periods, ...courseData } = data;
  return {
    periods:
      periods &&
      periods.sort((a, b) => {
        const tpA = convertPeriodToString(a);
        const tpB = convertPeriodToString(b);

        return tpB.localeCompare(tpA);
      }),
    ...courseData
  };
};

export const transformApiError = (error) => {
  switch (error.status) {
    case 401:
      return errorMessages.unauthorisedAccess;

    default:
      return errorMessages.genericError;
  }
};

export const transformSaveSmsSelectionsSuccess = (smsSuccessResponse) =>
  Array.isArray(smsSuccessResponse) ? smsSuccessResponse.map((res) => res.engagementId) : null;

export const transformNotes = (notes, workflow = SMS_WORKFLOW_PHONE_CALL) => {
  return notes && notes.length > 0
    ? notes
        .map((note) => ({
          ...note,
          callStatusLabel: callStatusLabels[workflow][note.statusId || NO_CALL_STATUS],
          callStatus: note.statusId || NO_CALL_STATUS
        }))
        .sort((a, b) => {
          const createdAtA = a.createdAt || JSON.stringify(a);
          const createdAtB = b.createdAt || JSON.stringify(b);
          return createdAtA.localeCompare(createdAtB);
        })
    : [];
};

export const transformStudentHistory = (
  { history, ongoing },
  workflow = SMS_WORKFLOW_PHONE_CALL
) => {
  const tranformedHistory =
    history &&
    history
      .map((historyRecord) => {
        const {
          week,
          riskId,
          riskReason,
          unitsHighRisk,
          avgWklyCanvasMins,
          lastAccessedDate,
          notes,
          statusId
        } = historyRecord;

        const transformedNotes = transformNotes(notes, workflow);

        return {
          week,
          riskLevel: riskLevelLabels[riskId],
          riskStatus: riskId,
          riskReasons: riskReason,
          riskReasonsNumber: riskReason ? riskReason.length : 0,
          highRiskUnits: unitsHighRisk,
          highRiskUnitsNumber: unitsHighRisk ? unitsHighRisk.length : 0,
          avgWklyCanvasMins,
          lastAccessedDate,
          notes: transformedNotes,
          callStatus: statusId,
          callStatusLabel: callStatusLabels[workflow][statusId]
        };
      })
      .sort((a, b) => a.week - b.week);

  const latestStatusId =
    tranformedHistory &&
    tranformedHistory[tranformedHistory.length - 1] &&
    tranformedHistory[tranformedHistory.length - 1].callStatus;

  const transformedHistoryData = {
    history: tranformedHistory,
    ...transformIndividualStudent(ongoing),
    callStatus: latestStatusId,
    callStatusLabel: callStatusLabels[SMS_WORKFLOW_PHONE_CALL][latestStatusId]
  };

  return transformedHistoryData;
};

export const transformSmsTemplates = (data) => {
  const smsTemplates = {};

  if (data && Array.isArray(data)) {
    data.forEach((item) => {
      smsTemplates[item.key] = { label: item.content.label, content: item.content.template };
    });
  }

  return smsTemplates;
};

export const transformStudentHistoryData = (data, workflow) => {
  const studentHistory = data.map((historyRecord) => {
    const transformed = { ...historyRecord };
    transformed.notes = transformNotes(historyRecord.notes, workflow);
    return transformed;
  });

  return studentHistory;
};
