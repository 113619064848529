import { apiEndpoints } from 'app/constants/apiEndpoints';
import { getPersonalInfoList } from 'hooks/useStudentDetails';

const createStudentsPIIStore = (set, get) => ({
  studentsPII: [],
  isFetchingStudentsData: false, // this value isnt currently used anywhere

  fetchStudentsData: async () => {
    const { unitId } = get().courseStore;

    if (!unitId) {
      return;
    }

    set((state) => {
      state.studentsPIIStore.isFetchingStudentsData = true;
    });

    const { apiRequest, handleApiError } = get().actions;

    // recursively fetch students, as we currently paginate the response to 350 results
    const fetchStudents = async (page = 1) => {
      const response = await apiRequest(apiEndpoints.studentPII(unitId, { page }));

      if (response.status !== 200) {
        handleApiError(response.status);
        return;
      }

      const { studentsPII } = get().studentsPIIStore;

      set((state) => {
        state.studentsPIIStore.studentsPII = [...studentsPII, ...response.data.students];
      });

      if (response.data.pagination.page === response.data.pagination.pageCount) {
        return;
      }

      await fetchStudents(page + 1);
    };

    await fetchStudents();

    set((state) => {
      state.studentsPIIStore.isFetchingStudentsData = false;
    });
  },

  getStudentInfoList(studentId) {
    const { studentsPII } = get().studentsPIIStore;
    const studentData = studentsPII.find((student) => student.studentId === studentId);

    return getPersonalInfoList({ ...studentData, withLabel: false });
  },

  getIsMobileNumber(studentId) {
    const { studentsPII } = get().studentsPIIStore;
    try {
      const studentData = studentsPII.find((student) => student.studentId === studentId);
      return studentData.isMobilePhone;
    } catch (e) {
      return console.log(`Getting error! studentId-${studentId} : ${e}`);
    }
  },

  getWithdrawnStatus(studentId) {
    const { studentsPII } = get().studentsPIIStore;
    try {
      const studentData = studentsPII.find((student) => student.studentId === studentId);
      return studentData.isWithdrawn;
    } catch (e) {
      return console.log(`Getting error! studentId-${studentId} : ${e}`);
    }
  }
});

export { createStudentsPIIStore };
